import type { ComponentType } from 'react'
import dynamic from 'next/dynamic'
import { useAmp } from 'next/amp'

const UserButtonAmp: ComponentType = dynamic(() =>
  import('./UserButtonAmp').then((module) => module.UserButtonAmp),
)

const UserButtonStandard: ComponentType = dynamic(() =>
  import('./UserButtonStandard').then((module) => module.UserButtonStandard),
)

export function UserButton(): JSX.Element {
  const isAmp = useAmp()

  if (isAmp) {
    return <UserButtonAmp />
  }

  return <UserButtonStandard />
}
